<template>
    <div class="auth-view">
      <div style="width: 100%">
        <div class="sys-title">
          <div class="name">云胶片</div>
          <div class="about">——随身携带，随时查阅</div>
        </div>
        <div class="input-box" v-if="needAuthShareCaptChaCode && !needCaptcha">

          <div class="auth-hint">为了您的数据安全，请输入分享链接的验证码！</div>
          <div class="input-row input-row">
            <input class="resident-id sc-input" type="text" maxlength="4" v-model="verifyValue"/>
          </div>
          <el-button class="btn-auth" type="primary" round @click="getVerify">确 定</el-button>
        </div>
      </div>

      <div v-if="verifyModeError" class="verifyModeError">
        {{ verifyModeError }}
      </div>
      <div v-if="verifyModeNoStudy" class="verifyModeNoStudy">
        {{ verifyModeNoStudy }}
      </div>

    </div>
</template>

<script>
import { store } from '../../utils/store'
import { MessageProxy } from '../../utils/MessageProxy'

export default {
    name: 'IdentityCheck',
    components: {
    },
    data() {
        return {
            captcha: '',
            needCaptcha: false,
            needAuth: false,
            needAuthShareCaptChaCode: true,
            showAuthLoading: false,
            showHistory: false,
            src:'',
            verifyValue: '',
            hospital: { // 改检查的医院信息
                enableFlag: true,
                identityCheckMode : 'idCard'
            },
            verifyModeError: '',
            verifyModeNoStudy: ''
        }
    },
    async created() {
        await this.init();

    },
    methods: {
        async init () { // 初始化
            // 微信公众号进入
            if (store.userId != undefined && store.userId != '' && location.pathname.endsWith('/index.html')) {
                store.isAuthenticated = true;
            }
            this.needAuthShareCaptChaCode = true;

            //处理/s/f85ec65a2a54431db187b269b7e54097 验证码:6302
            //从字符串头处理 截取到空格
            if (this.$route.params.studyID.indexOf(' ') > -1) {
                this.studyID = this.$route.params.studyID.substring(0, this.$route.params.studyID.indexOf(' '));
            } else {
                this.studyID = this.$route.params.studyID;
            }

            if (!this.studyID) {
                this.$message({
                    message: 'studyID 为空',
                    type: 'error',
                    showClose: true,
                });
                return
            }
        },
        getVerify () {
            if ('' == this.verifyValue) {
                this.$message.error("请输入分享码！");
                return;
            }
            MessageProxy.getVerifyShareCaptchaCode(store.userId, store.hospital.shortName, this.studyID, this.verifyValue).then((result)=>{
                if (true == result.hasError) {
                    this.$message({
                        message: result.errorText,
                        type: 'error',
                        showClose: true,
                    });
                    return;
                }
                if (result.data != null && result.data.code != '200') {
                    this.$message({
                        message: result.data.message,
                        type: 'error',
                        showClose: true,
                    });
                    return;
                }
                if (result.data.desc == '验证失败') {
                    this.$message({
                        message: result.data.desc,
                        type: 'error',
                        showClose: true,
                    });
                    return
                }
                // this.study = result.data.data;
                if(result.data.data.studyid == undefined || result.data.data.studyid == ''){
                  this.$message({
                    message: '未查询到相关联的检查信息，请重新获取分享链接。',
                    type: 'error',
                    showClose: true,
                  });
                }
                store.studyID = result.data.data.studyid;
                store.isAuthenticated = true;
                this.needAuth = false;
                //验证成功后存储到 localStorage
                const cacheKey = 'verifyResult';
                localStorage.setItem(cacheKey, JSON.stringify({ result: this.studyID, studyId: result.data.data.studyid, timestamp: Date.now() }))
                this.$router.push({
                  path: '/v/' + this.$route.params.studyID,
                  query: this.$route.query
                });
            })
        },

    }
}
</script>

<style scoped>

.verifyModeError {
    height: 150px;
    width: 100%;
    background-image: url("../../assets/error_icon.svg");
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: end;
    position: absolute;
    top: 35%;
    text-align: center;
    background-position: center center;
    color: #ff8888;
}

.verifyModeNoStudy {
  height: 150px;
  width: 100%;
  background-image: url("../../assets/no-data-icon.svg");
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: end;
  position: absolute;
  top: 35%;
  text-align: center;
  background-position: center center;
  color: #ff8888;
}

.cell{
    display: flex;
}

.cell>.val{
    flex: 1;
}

.auth-view {
        position:fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 9;
        backdrop-filter: blur(6px);
        background-image: linear-gradient(rgba(236, 245, 255, 0.5) 5%, rgba(255, 255, 255, 1) 15%);

        display: flex;
        justify-content: center;

    }
    .auth-view .input-box {

        padding: 8vmin;
        margin: 1vmin;
        border-radius: 1vmin;
        /* border: solid #409eff 1px; */
    }
    .auth-view .auth-hint {
        font-size: 4vmin;
      text-align: left;
      font-weight: bold;
        margin-bottom: 5vmin;
    }
    .auth-view .resident-id {
        font-size: 7.5vmin;
        text-align: center;
        width: 80vmin;
        border: none;
      text-align: center;
      letter-spacing: 15px;
    }
    .auth-view .patient-ming {
        font-size: 6.5vmin;
    }
    .auth-view .patient-xing {
        font-size: 6.5vmin;
        width: 20vmin;
      margin-right: 10px;
        text-align: center;
    }
    .auth-view .input-box .btn-auth {
        margin-top: 6vmin;
        width: 80vmin;
      border-radius: 8px;
      font-size: 4vmin;
    }
    .sc-input {
        box-sizing: border-box;
        /* background-color:#fff; */
        color:#000;
        border: 1px solid rgba(236, 230, 255, 1) !important;
        height: 12vmin;
        line-height: 12vmin;
        padding: 0;
        outline: none;
      border-radius: 8px;
    }
    .sc-input:focus {
        border-color: #409eff !important;
    }
.auth-view{
  background:url("../../assets/codebg.jpg") no-repeat #F1F8FF;
  background-size: 100%;
}
    .auth-view .test-run {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 2vmin;
        color: red;
        font-size: 3vmin;

    }
    .sys-title{
      text-align: left;
      margin-top: 20vmin;
      margin-left: 9vmin;
      margin-bottom: 15vmin;

    }

.name{
  font-size: 8vmin;
  font-weight: bold;
}
.about{
  font-size: 4vmin;
  font-weight: bold;
}
</style>
