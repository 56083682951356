import md5 from 'js-md5'
import {UrlHelper, Utils} from './Utils'
import {store} from './store'

export class MessageProxy {
    static sqlFolder = "efilmapi";
    static queryPath = "qry";
    static insertPath = "add";
    static updatePath = "mdf";
    static deletePath = "rmv";

    // static queryPath = MessageProxy.sqlFolder;
    // static insertPath = MessageProxy.sqlFolder;
    // static updatePath = MessageProxy.sqlFolder;
    // static deletePath = MessageProxy.sqlFolder;

    static get(url) {
        let options = {
            method: "GET"
        };
        return MessageProxy.request(url, options);
    }

    static post(url, param, contentType) {
        let options;
        if (contentType) {
            options = {
                method: "POST",
                body: param,
                headers: {
                    "AppId": "MYPACS",
                    "Content-Type": contentType	//"application/json"等
                }
            }
        } else {
            options = {
                method: "POST",
                body: param
            }
        }
        return MessageProxy.request(url, options);
    }

    static post2api(url, param) {
        const session = localStorage.getItem("sessionID") || 'MYPACS';

        const serverTimeStamp = new Date().getTime() + Number(window.difference || 0);

        const randomString = Utils.getRandomString(8); // 随机字符串
        let currTime = new Date().getTime();
        let Signature = serverTimeStamp + session + '*Tup8@0' + randomString + serverTimeStamp

        let options;
        options = {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                // "AppId": "MYPACS",
                // "Content-Type": "application/json",
                "Context_Api_Time": currTime,
                "Context_Api_Sign": md5(currTime + ".&%213324dsf&%%$$#@"),
                "sessionID": session,
                'MySession': session,
                'MyTimeStamp': serverTimeStamp,
                'Signature': md5(Signature).toUpperCase(),
                "nonce": randomString,
                "Appid": "MYPACS"
            }
        }
        if (!(param instanceof FormData) && !options.headers["Content-Type"]) {
            options.headers["Content-Type"] = "application/json;charset=utf-8"
        }

        // 'MySession': session,
        // 'MyTimeStamp': serverTimeStamp,
        // 'Signature': md5(serverTimeStamp + session + '*Tup8@0' + randomString + serverTimeStamp).toUpperCase(),
        // "nonce": randomString,
        // "Appid":window.config.appId,
        // ...config.headers,
        // if(header) {
        // 	options.headers = header
        // 	options.headers["Content-Type"] = "application/json"
        // 	options.headers["Context_Api_Time"] = currTime
        // 	options.headers["Context_Api_Sign"] = md5(currTime + ".&%213324dsf&%%$$#@")
        // }
        return MessageProxy.request(url, options);
    }

    // 文件流
    static postBlob(url, param) {
        let options = {
            method: "POST",
            body: JSON.stringify(param),
            responseType: 'blob',
            headers: {
                "AppId": "MYPACS",
                "Content-Type": "application/json",
            }
        }
        return MessageProxy.request(url, options);
    }


    static request(url, options) {
        let result = {
            hasError: false,
            errorText: "",
            data: null
        }
        return new Promise(function (resolve) {
            fetch(url, options)
                .then((response) => {
                    if (response.ok) {
                        if (url.endsWith('.txt')) {
                            response.text().then(text => {
                                result.data = text;
                                resolve(result);
                            })
                        } else if (options.responseType == "blob") {
                            // 文件流
                            resolve(response);
                        } else {
                            response.json().then((data) => {
                                data.message = data.message || data.desc;
                                result.data = data;
                                resolve(result);
                            })
                        }
                    } else {
                        result.hasError = true;
                        if (response.status == 404) {
                            result.errorText = `请求的 URL 不存在：${response.url}`;
                        } else {
                            result.errorText = `错误：${response.status} ${response.url}`;
                        }
                        resolve(result);
                    }
                })
                .catch((error) => {
                    result.hasError = true;
                    result.errorText = error;
                    resolve(result);
                })
        })
    }

    static queryStudyYears(patientId) {
        let reqUrl = UrlHelper.reqUrl(`/cloudfilm/${MessageProxy.queryPath}/pacs.queryStudyListTime/execute.json`);

        let formData = new FormData();
        formData.append("patientId", patientId);

        return MessageProxy.post(reqUrl, formData);
    }

    static queryStudyHospitals(patientId) {
        let reqUrl = UrlHelper.reqUrl(`/cloudfilm/${MessageProxy.queryPath}/pacs.queryStudyListHospital/execute.json`);

        let formData = new FormData();
        formData.append("patientId", patientId);

        return MessageProxy.post(reqUrl, formData);
    }

    static queryStudyModalities(patientId) {
        let reqUrl = UrlHelper.reqUrl(`/cloudfilm/${MessageProxy.queryPath}/pacs.queryStudyListModality/execute.json`);

        let formData = new FormData();
        formData.append("patientId", patientId);

        return MessageProxy.post(reqUrl, formData);
    }

    static queryStudyByPatientId(patientId) {
        let reqUrl = UrlHelper.reqUrl(`/cloudfilm/${MessageProxy.queryPath}/pacs.queryStudyByPatientID/execute.json`);

        let formData = new FormData();
        formData.append("patientID", patientId);

        return new Promise(function (resolve) {
            MessageProxy.post(reqUrl, formData).then(function (result) {
                if (true == result.hasError) {
                    resolve(result);
                    return;
                }
                result.studies = MessageProxy.parseStudyData(result.data.data);
                resolve(result);
            })
        })
    }

    static queryStudyByStudyUid(studyUid) {
        let reqUrl = UrlHelper.reqUrl(`/cloudfilm/${MessageProxy.queryPath}/pacs.queryStudyByStudyUid/execute.json`);

        let formData = new FormData();
        formData.append("studyUid", studyUid);

        return new Promise(function (resolve) {
            MessageProxy.post(reqUrl, formData).then(function (result) {
                if (true == result.hasError) {
                    resolve(result);
                    return;
                }
                // result.studies = MessageProxy.parseStudyData(result.data.data);
                resolve(result);
            })
        })
    }

    static parseStudyData(data) {
        let studyList = [];

        if (null == data || data.length == 0) {
            return studyList;
        }

        data.forEach(rec => {
            let study = {};
            study.risStudyId = rec.studyinfo_uid;
            study.studyUid = rec.pacs_study_uid;
            study.studyId = rec.study_id;
            study.studyTime = rec.study_time;
            study.studyItem = rec.study_item;
            study.studyDescription = rec.study_description;
            study.patientId = rec.patient_master_index;
            study.patientName = rec.patient_name != undefined && rec.patient_name != "" ? rec.patient_name : rec.patient_en_name;
            study.patientEnName = rec.patient_en_name;
            study.patientWeight = rec.patient_weight;
            study.patientHeight = rec.patient_height;
            study.patientBirthday = rec.patient_birthday;
            study.patientAge = rec.patient_age;
            study.patientAgeUnit = rec.patient_age_unit;
            study.patientGender = rec.patient_gender;
            study.patientIdNumber = rec.patient_id_number;
            study.patientPhoneNumber = rec.patient_phone_number;
            study.patinetAddress = rec.patinet_address;
            study.patientFolk = rec.patient_folk;
            study.patientSociteyId = rec.patient_society_card;
            study.applyDepartment = rec.apply_department_name;
            study.applyHospital = rec.apply_hospital_name;
            study.doctorName1 = rec.rpt_doctor_name_1;
            study.doctorReportEndTime1 = rec.doctor_report_end_time_1;
            study.doctorName2 = rec.rpt_doctor_name_2;
            study.doctorReportEndTime2 = rec.doctor_report_end_time_2;
            study.disableFlag = rec.disableflag;
            study.modality = rec.modality;
            study.chiefComplaint = rec.chief_complaint;
            study.clinicalDiagnose = rec.clinical_diagnose;
            study.reportDescription = rec.rpt_desc;
            study.reportDiagnose = rec.rpt_diagnose;
            study.reportId = rec.reportid;
            study.token = rec.token;
            study.hospitalName = rec.hospital_name;
            study.imageCount = rec.image_count;

            studyList.push(study);
        });
        return studyList;
    }

    //向手机发送短信验证码
    static sendSMSCode(phoneNumber) {
        const reqParam = {
            act: "send-sms",
            phoneNum: phoneNumber
        };
        const reqUrl = UrlHelper.reqUrl("/cloudfilm/sms/smsVerification");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    //验证手机短信
    static verifySMSCode(phoneNumber, smsCode, graphicCode) {
        const reqParam = {
            act: "verify-sms",
            phoneNum: phoneNumber,
            smsCode: smsCode,
            graphicCode: graphicCode
        };
        const reqUrl = UrlHelper.reqUrl("/cloudfilm/sms/smsVerification");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    //重新获取图形验证码
    static getGraphicCode(phoneNumber, smsCode) {
        const reqParam = {
            act: "get-graphic-code",
            phoneNum: phoneNumber,
            smsCode: smsCode
        };
        const reqUrl = UrlHelper.reqUrl("/cloudfilm/sms/smsVerification");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static getUserInfo(wxOpenId, phoneNumber) {
        let reqParam = {};
        if (wxOpenId != undefined && wxOpenId != "") {
            reqParam = {
                wxOpenId: wxOpenId
            }
        } else if (phoneNumber != undefined && phoneNumber != "") {
            reqParam = {
                phoneNum: phoneNumber
            }
        }
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/user/getUserInfo");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    // 第三方统计openid
    static addPatient(reqParam) {
        let reqUrl = 'https://ydsy.myfilm.cc/hebydsy/wechat/add_patient';
        return MessageProxy.post(reqUrl, JSON.stringify(reqParam));
    }

    //获取真实ip
    static getRealIp() {
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/ddns/ipInfo");
        return MessageProxy.post2api(reqUrl, {});
    }

    static addUserLog(userId, operation, loginType, hospitalId) {
        let reqParam = {
            userId: userId,
            operation: operation
        }
        if (loginType != undefined && loginType != "") {
            reqParam.loginType = loginType;
        }
        if (hospitalId != undefined && hospitalId != "") {
            reqParam.hospitalId = hospitalId;
        }
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/user/addUserLog");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static getHospitalList(params) {
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/hospital/getHospitalList");
        return MessageProxy.post2api(reqUrl, params);
    }

    static getGoodsInfo(hospitalId) {
        let reqParam = {
            goodsType: "存储图像",
            hospitalId: hospitalId
        };
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/hospital/getGoodsInfo");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static getHospitalInfo(hospitalShortName) {
        let reqParam = {hospitalShortName: hospitalShortName};
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/hospital/getHospitalInfo");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static getPayInfo(studyId) { // 获取支付信息
        let reqParam = {ris_study_id: studyId};
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/order/getPayInfo");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static getWXaccess_token(parm) { // 获取微信openid等信息
        parm.appid = store.wx.appid
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/getWXaccess_token");
        return MessageProxy.post2api(reqUrl, parm);
    }

    static pay(url, data) { // 获取微信openid等信息
        return MessageProxy.post(url, data);
    }

    static getOrderNum(parm) { // 微信生成订单
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/order/getOrderNum");
        const guid = () => {
            return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }
        let header = {
            appId: store.wx.ORDER_APP_ID,
            timeStamp: Math.round(new Date()) + '',
            nonce: guid(),
            sign: ""
        }
        let sign = header.timeStamp + header.nonce + store.wx.ORDER_APP_KEY + store.wx.ORDER_APP_SECRET
        header.sign = md5(sign)
        parm.headers = header
        parm.payChannel = 'wechat'
        parm.payScene = '2001'
        parm.orderapi = store.wx.orderapi
        //businessOrderNum
        // return MessageProxy.post2api(reqUrl, parm);
        var result = MessageProxy.post2api(reqUrl, parm);
        // console.log('getOrderNum---', result)
        // if (result.code === 200) {
        // 	result = JSON.parse(result.message)
        // }
        return result
    }

    static savePaymentInfo(parm) {
        // console.log('savePaymentInfo------------api')
        // (userId, hospitalId, risStudyId, goodsId, transactionId, patientName, wechatId, mobilePhone)
        // let reqParam = {
        //     userId: userId,
        //     hospitalId: hospitalId,
        //     risStudyId: risStudyId,
        //     goodsId: goodsId,
        //     transactionId: transactionId,
        //     patientName: patientName,
        //     wechatId: wechatId,
        //     mobilePhone: mobilePhone
        // };
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/order/savePaymentInfo");
        return MessageProxy.post2api(reqUrl, parm);
    }

    static getProtocol() {
        return MessageProxy.get('./数字影像浏览服务协议.txt');
    }

    static getStudyList(queryType, queryCondition) {
        let reqParam = {};
        if ("searchStudy" == queryType) {
            reqParam = {
                queryType: queryType,
                userId: queryCondition.userId,
                patientName: queryCondition.patientName,
                queryKey: queryCondition.queryKey,
                hospitalId: queryCondition.hospitalId
            };
        } else if ("concernedPerson" == queryType) {
            reqParam = {
                queryType: queryType,
                userId: queryCondition.userId,
                concernedPersonName: queryCondition.concernedPersonName
            };
        }
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/study/getStudyList");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    //获取检查信息
    static getStudyById(userId, hospitalShortName, risStudyId) {
        // console.log("getStudyById userId", userId)
        let reqParam = {userId: userId, hospitalShortName: hospitalShortName, risStudyId: risStudyId};
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/study/getStudyById");

        // let reqUrl = UrlHelper.reqUrl("getStudyFromFront");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    //获取检查信息
    static getVerifyMode(userId, hospitalShortName, risStudyId) {
        // console.log("getStudyById userId", userId)
        let reqParam = {userId: userId, hospitalShortName: hospitalShortName, risStudyId: risStudyId};
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/study/verify/mode");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    // 验证
    static getVerify(userId, hospitalShortName, risStudyId, verifyValue) {
        // console.log("getStudyById userId", userId)
        let reqParam = {
            userId: userId,
            hospitalShortName: hospitalShortName,
            risStudyId: risStudyId,
            verifyValue: verifyValue
        };
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/study/verify");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    // 验证分享码
    static getVerifyShareCaptchaCode(userId, hospitalShortName, risStudyId, verifyValue) {
        let reqParam = {
            userId: userId,
            hospitalShortName: hospitalShortName,
            risStudyId: risStudyId,
            verifyValue: verifyValue,
            type: 'share'
        };
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/study/verify");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static getBrowseHistory(userId) {
        let reqParam = {userId: userId};
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/study/getBrowseHistory");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static collectStudy(userId, risStudyId, patientName, hospitalId) {
        let reqParam = {
            userId: userId,
            risStudyId: risStudyId,
            patientName: patientName,
            hospitalId: hospitalId
        };
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/study/collectStudy");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static cancelCollectStudy(userId, risStudyId) {
        let reqParam = {userId: userId, risStudyId: risStudyId};
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/study/cancelCollectStudy");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static getConcernedPatientList(userId) {
        let reqParam = {userId: userId};
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/patient/getConcernedPatientList");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static updatePatientNickname(userId, patientName, nickname) {
        let reqParam = {userId: userId, patientName: patientName, nickname: nickname};
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/patient/updatePatientNickname");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static deleteConcernedPatient(userId, patientName) {
        let reqParam = {userId: userId, patientName: patientName};
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/patient/deleteConcernedPatient");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static getImageGoods() {
        let reqParam = {goodsType: '存储图像'};
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/hospital/getGoodsInfo");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static getToken(userId, risStudyId, period, hospitalId, captchaCode) {
        let reqParam = {act: 'gettoken', vendorid: 'efilm', username: userId, studyid: risStudyId, period: period, hospitalId: hospitalId, captchaCode: captchaCode};
        // let reqUrl = UrlHelper.reqUrl("/cloudfilm/user/authorization");
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/user/authorization");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    //获取检查信息,通过分享码
    static getStudyIdByToken(token) {
        let reqParam = {token: token};
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/study/getStudyIdByToken");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static getStudyIdByShortCode(code, hospitalShortName) {
        let reqParam = {
            studyShortCode: code,
            hospitalShortName: hospitalShortName
        }
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/study/getStudyIdByShortCode");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static getStudyIdByShareCode(authCode) {
        let reqParam = {act: 'getStudyIdByShareCode', authcode: authCode};
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/user/authorization");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static getPayStatus(parm) {
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/order/getPayStatus");
        return MessageProxy.post2api(reqUrl, parm);
    }

    static getAuthorizationCode(userId, risStudyId, period, hospitalId, captchaCode) {
        let reqParam = {
            act: 'getAuthorizationCode',
            vendorid: 'pacs',
            username: userId,
            studyid: risStudyId,
            period: period,
            hospitalId: hospitalId,
            captchaCode: captchaCode
        };
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/user/authorization");
        return MessageProxy.post2api(reqUrl, reqParam);
    }

    static getCaptcha(param) {
        let reqUrl = UrlHelper.reqUrl("/common/auth/captcha");
        // let reqUrl = 'http://dev.myfilm.cc:10323/rispacsgateway/miis/gateway/api/web/common/auth/captcha';
        return MessageProxy.postBlob(reqUrl, param);
    }

    static captchaVerify(param) {
        // let reqUrl = `http://dev.myfilm.cc:10323/rispacsgateway/miis/gateway/api/web/common/auth/captcha`;
        let reqUrl = UrlHelper.reqUrl("common/auth/captchaVerify");
        return MessageProxy.post2api(reqUrl, param);
    }

    static getDecryptParam(param) {
        let reqUrl = UrlHelper.reqUrl("/cloudfilm/secure/decryptParam");
        return MessageProxy.post2api(reqUrl, param);
    }


}