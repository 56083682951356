const routers = [
    // {
    //     path: '/login',
    //     name: 'login',
    //     // component: LoginView,
    //     component: () => import(/* webpackChunkName: "login-view" */ '@/components/user/LoginView.vue'),
    //     meta: {
    //       auth: false, // 此组件需要登录验证
    //     }
    // },
    // {
    //     path: '/',
    //     redirect: '/login'
    // },
    {
        path: '/main',
        name: 'main',
        // component: MainView,
        component: () => import(/* webpackChunkName: "main-view" */ '@/components/MainView.vue'),
        meta: {
          auth: true, // 此组件需要登录验证
        },
        children: [
            {
                path: 'recent',
                // component: UserBrowseHistoryView
                component: () => import(/* webpackChunkName: "recent-view" */ '@/components/study/UserBrowseHistoryView.vue'),
            },
            {
                path: 'search',
                // component: StudySearchView
                component: () => import(/* webpackChunkName: "search-view" */ '@/components/study/StudySearchView.vue'),
            },
            {
                path: 'concern',
                // component: ConcernedPersonListView
                component: () => import(/* webpackChunkName: "concern-view" */ '@/components/study/ConcernedPersonListView.vue'),
            },
            {
                path: 'user',
                // component: UserView,
                component: () => import(/* webpackChunkName: "user-view" */ '@/components/user/UserView.vue'),
                children: [
                    {
                        path: 'info',
                        // component: UserInfoBrowseView,
                        component: () => import(/* webpackChunkName: "info-view" */ '@/components/user/UserInfoBrowseView.vue'),
                        children: [
                            {
                                path: 'icon',
                                // component: UserIconEditView
                                component: () => import(/* webpackChunkName: "icon-view" */ '@/components/user/UserIconEditView.vue'),
                            },
                            {
                                path: 'name',
                                // component: UserNameEditView
                                component: () => import(/* webpackChunkName: "name-view" */ '@/components/user/UserNameEditView.vue'),
                            },
                            {
                                path: 'phone',
                                // component: UserPhoneEditView
                                component: () => import(/* webpackChunkName: "phone-view" */ '@/components/user/UserPhoneEditView.vue'),
                            }
                        ]
                    },
                    {
                        path: 'concern',
                        // component: PatientListView
                        component: () => import(/* webpackChunkName: "concern-view" */ '@/components/user/PatientListView.vue'),
                    }
                ]
            }
        ]
    },
    {
        path: '/hospitallist',
        name: 'hospitallist',
        // component: HospitalListView,
        component: () => import(/* webpackChunkName: "hospitallist-view" */ '@/components/study/HospitalListView.vue'),
        meta: {
            wechat: true, // 此组件需要微信环境，获取微信openid
            auth: true, // 此组件需要登录验证
        }
    },
    {
        path: '/studylist',
        name: 'studylist',
        component: () => import(/* webpackChunkName: "studylist-view" */ '@/components/study/StudyListView.vue'),
        meta: {
          auth: true, // 此组件需要登录验证
        }
    },
    {
        path: '/studylistV3',
        name: 'studylistV3',
        component: () => import(/* webpackChunkName: "studylist-view" */ '@/components/study/StudyListViewV3.vue'),
        meta: {
          auth: false, // 此组件需要登录验证
        }
    },
    {
        path: '/studylistV4',/* 应石老师添加，仅针对扎兰屯医院 */
        name: 'studylistV4',
        component: () => import(/* webpackChunkName: "studylist-view" */ '@/components/study/StudyListViewV4.vue'),
        meta: {
          auth: false, // 此组件需要登录验证
        }
    },
    {
        path: '/studylistV2/:studyID', // 哈四定制，（判断医院的属性“showHistory”标识信息）
        name: 'studylistV2',
        component: () => import(/* webpackChunkName: "studylistV2-view" */ '@/components/study/StudyListViewV2.vue'),
        meta: {
            studyID: true, // 可以用token || shareCode 获取studyID 进入页面
            auth: false, // 此组件需要登录验证
        }
    },
    {
        path: '/search',
        name: 'searchV2',
        // component: StudySearchView
        component: () => import(/* webpackChunkName: "search-view" */ '@/components/study/StudySearchViewV2.vue'),
        meta: {
            // studyID: true, // 可以用token || shareCode 获取studyID 进入页面
            auth: false, // 此组件需要登录验证
        }
    },
    {
        path: '/studyinfo/:studyID',
        name: 'studyinfo',
        // component: SingleStudyView,
        component: () => import(/* webpackChunkName: "recent-view" */ '@/components/study/IdentityCheck.vue'),
        // component: () => import(/* webpackChunkName: "studyinfo-view" */ '@/components/study/SingleStudyView.vue'),
        meta: {
            studyID: true, // 可以用token || shareCode 获取studyID 进入页面
            wechat: true, // 此组件需要微信环境，获取微信openid
            auth: false, // 此组件需要登录验证
        }
    },
    {
        path: '/studyinfoDetail/:studyID',
        name: 'studyinfoDetail', // 身份校验
        component: () => import(/* webpackChunkName: "recent-view" */ '@/components/study/SingleStudyView.vue'),
        meta: {
            studyID: true, // 可以用token || shareCode 获取studyID 进入页面
            auth: false, // 此组件需要登录验证
        }
    },
    {
        path: '/v/:studyID',
        name: 'v', // 身份校验
        component: () => import(/* webpackChunkName: "recent-view" */ '@/components/study/SingleStudyNoShareView.vue'),
        meta: {
            studyID: true, // 可以用token || shareCode 获取studyID 进入页面
            auth: false, // 此组件需要登录验证
        }
    },
    {
        path: '/s/:studyID',
        name: 'studyinfoCaptch', // 分享码进入
        component: () => import(/* webpackChunkName: "recent-view" */ '@/components/study/IdentityCheckCaptchaCode.vue'),
        meta: {
            studyID: true, // 可以用token || shareCode 获取studyID 进入页面
            auth: false, // 此组件需要登录验证
        }
    },
    //因短链分享 此路由废弃
    // {
    //     path: '/s/:studyID',    //同 /studyinfo/:studyID
    //     name: 'studyinfo',
    //     // component: SingleStudyView,
    //     component: () => import(/* webpackChunkName: "studyinfo-view" */ '@/components/study/SingleStudyView.vue'),
    //     meta: {
    //         studyID: true, // 可以用token || shareCode 获取studyID 进入页面
    //         wechat: true, // 此组件需要微信环境，获取微信openid
    //         auth: false, // 此组件需要登录验证
    //     }
    // },
    {
        path: '/report/:id',
        name: 'report',
        // component: ReportSnapshotView,
        component: () => import(/* webpackChunkName: "report-view" */ '@/components/study/ReportSnapshotView.vue'),
        meta: {
          auth: true, // 此组件需要登录验证
        }
    },
    {
        // 会匹配所有路径 404
        path: '*',
        // component: N404View,
        component: () => import(/* webpackChunkName: "404-view" */ '@/components/N404View.vue'),
        name: '404',
        meta: {
          auth: true, // 此组件需要登录验证
          // keepAlive: true, // 此组件需要被缓存
          title: 'this is 404',
          description: '404'
        }
    },
    // {
    //     path: '/pclogin',
    //     name: 'pclogin',
    //     component: () => import(/* webpackChunkName: "pcviews" */ '@/pcviews/Login.vue'),
    //     meta: {
    //         auth: false, // 此组件需要登录验证
    //     }
    // },
    {
        path: '/pcmain',
        name: 'pcmain',
        component: () => import(/* webpackChunkName: "pcviews" */ '@/pcviews/Main.vue'),
        meta: {
            auth: true // 此组件需要登录验证
        }
    },

]

export default routers