<template>
    <div class="comp-container">
        <div class="search-view" v-show="searchViewVisible">
            <div class="patient-box">
                <div>请输入就诊人姓名</div>
                <el-input class="input-patient-name" placeholder="姓名全名" v-model="patientName" clearable></el-input>
            </div>
            <div class="key-box">
                <div>请输入体检号、门诊号、住院号、身份证号</div>
                <el-input class="input-query-key" placeholder="门诊号、或住院号" v-model="queryKey" clearable></el-input>
                <div class="query-key-hint">输入体检号、门诊号、住院号、身份证号任意一个</div>
                <!-- <div class="query-key-hint">输入门诊号、或住院号</div>-->
            </div>
            <div class="hospital-box" v-if="showSelectedHospital" @click="showHospitalList">
                <div>请选择就诊医院</div>
                <el-input placeholder="选择就诊医院" v-model="hospitalName" readonly suffix-icon="el-icon-arrow-right"></el-input>
            </div>
            <div class="search-box">
                <el-button class="btn-search" type="primary" @click="searchStudy">查找检查</el-button>
            </div>
        </div>
        <div class="query-result-view" v-if="resultViewVisible">
            <!-- <StudyListView :patientName="patientName" v-on:closeView="closeResultView" /> -->
        </div>
        <div class="popup-view-container hide" ref="popViewContainer">
            <HospitalListView v-on:closeView="closePopupView" v-on:selectHospital="onSelectHospital"/>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
import HospitalListView from './HospitalListView.vue'
import { store } from '../../utils/store'
import { MessageProxy } from '../../utils/MessageProxy'
import { UrlHelper } from '../../utils/Utils'

export default {
    name: 'StudySearchView',
    components: {
        HospitalListView,
        // StudyListView
    },
    data() {
        return {
            searchViewVisible: true,
            resultViewVisible: false,
            showSelectedHospital: true,
            selectedHospital: null,
            patientName: "",
            queryKey: ""
        }
    },
    computed: {
        hospitalName: function() {
            if (null == this.selectedHospital) {
                return "";
            }
            return this.selectedHospital.name;
        }
    },
    beforeCreate() {
      let wxOpenId = UrlHelper.parse('openid');
      const url = location.href
      if (undefined !== wxOpenId && "" !== wxOpenId) {
            store.wxOpenId = wxOpenId;
      } else {
          if (url.indexOf('ydsy') > 0) {
              let newurl = `https://ydsy.myfilm.cc/hebydsy/wechat/authorize?redirect_uri=${encodeURIComponent(url)}`;
              window.location.href = newurl;
          }
      }
    },
    async created() {
      if (store.hospital.id) {
        this.selectedHospital = store.hospital;
      } else {
        if (store.hospital.shortName) {
          try {
            let result = await MessageProxy.getHospitalInfo(store.hospital.shortName)

            if (true == result.hasError) {
              console.error(result.errorText);
              return;
            }
            if (result.data != null && result.data.code != '200') {
              console.error(result.data.message);
              return;
            }
            if (result.data.data != null) {
              store.hospital.id = result.data.data.id;
              this.selectedHospital = store.hospital;
            }
            this.showSelectedHospital = !this.selectedHospital
          } catch {
            this.$message.error("请刷新页面或重新点击进入！");
          }
        }
      }
    },
    // mounted() {
    //     console.log("$route", this.$route)
    // },
    // updated() {
    //     console.log("updated $route", this.$route)
    // },
    methods: {
        // selectHospital() {
        //     this.$message.info("选择医院");
        // },
        searchStudy() {
            if ("" === this.patientName) {
                this.$message.error("请输入就诊人姓名！");
                return;
            }
            if ("" === this.queryKey) {
                this.$message.error("请输入门诊号、住院号！");
                return;
            }
            if (this.queryKey.length < 6) {
                this.$message.error("请输入完整的门诊号、住院号！");
                return;
            }
            // if (true === this.selectedHospital) {
            //     this.$message.error("请选择就诊医院！");
            //     return;
            // }
            // this.resultViewVisible = true;
            let hospitalId = "";
            if (this.selectedHospital != null) {
                hospitalId = this.selectedHospital.id;
            }
            // let path = this.$route.fullPath.replace('/search' , '/studylistV2/xxxx')
            // path += '&patientName=' + this.patientName;
            // path += '&queryKey=' + this.queryKey;
            // path += '&hospitalId=' + hospitalId;
            
            // this.$router.push(path);
            

            this.$router.push({path: 'studylistV2/xxxx', query:{
                patientName: this.patientName, 
                queryKey: this.queryKey,
                hospitalId: hospitalId
            }});
        },
        onSelectHospital(hospitalId) {
            let hospital = null;
            for (const hosp of store.hospitals) {
                if (hosp.id == hospitalId) {
                    hospital = hosp;
                }
            }
            this.selectedHospital = hospital;
            this.closePopupView();

            store.hospital.id= hospital.id
            store.hospital.name= hospital.name
            store.hospital.shortName= hospital.shortName
        },
        showHospitalList() {
            let elPopViewContainer = this.$refs.popViewContainer;
            elPopViewContainer.classList.remove('hide');
            // this.$router.push('/hospitallist');
        },
        closePopupView() {
            let elPopViewContainer = this.$refs.popViewContainer;
            elPopViewContainer.classList.add('hide');
        },
        closeResultView() {
            this.resultViewVisible = false;
        }
    }
}
</script>

<style scoped>
    .comp-container {
        height: 100%;
    }
    .search-view,
    .hospital-view,
    .result-view {
        box-sizing: border-box;
        height: 100%;
        overflow-y: auto;
    }
    .search-view {
        padding: 10vmin 10vmin 0 10vmin;
        text-align: left;
        font-size: 4vmin;
    }
    .search-view .key-box,
    .search-view .hospital-box {
        margin-top: 6vmin;
    }
    .search-view .query-key-hint {
        font-size: 3vmin;
        margin-top: 1vmin;
        color: #999;
    }
    .search-view .search-box {
        margin-top: 20vmin;
        text-align: center;
    }
    .search-view .search-box .btn-search {
        width: 100%;
        font-size: 4vmin !important;
    }

    .query-result-view,
    .popup-view-container {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #f6f6f6;
        z-index: 2;
    }
    .popup-view-container.hide {
        display: none;
    }

</style>